import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useGetDiscounts } from '@saatva-bits/pattern-library.modules.promotions'
import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'
import { useProductState } from '@saatva-bits/pattern-library.modules.selection'
import { DetailProductTile } from '@saatva-bits/pattern-library.components.detail-product-tile'
import { handleTag } from '@saatva-bits/pattern-library.modules.detail-modal'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'

import { getDetailTileArdadImages } from '@/utils/product'

import productCodes from '@/temp-configs/product-codes'

const productsSort = [
    productCodes.classic,
    productCodes.saatvaRX,
    productCodes.latexHybrid,
    productCodes.contour5,
    productCodes.memoryFoamHybrid,
    productCodes.zenhaven
]

const ProductTile = ({ product, containerClassname }) => {
    const {
        isNew,
        tag: productTag,
        url,
        category,
        subcategory,
        productCode,
        title,
        subtitle,
        ardadDescriptors,
        lowestPrice,
        highestPrice,
    } = product
    const { getDiscounts } = useGetDiscounts()
    const productState = useProductState(productCode, ['assets', 'genericName', 'sku', 'productCode'])
    const { isV1: isFavoriteIconEnabled } = useExperiment('EXP.FAVORITES.DIS-530')


    const baseProduct = {
        sku: productCode,
        parentSku: productCode,
        category: category.toLowerCase(),
        quantity: 1,
        isPrimaryProduct: true
    }

    const { finalPrice: lowestPriceDiscount } = getDiscounts([{ ...baseProduct, price: lowestPrice }])
    const { finalPrice: highestPriceDiscount } = getDiscounts([{ ...baseProduct, price: highestPrice }])

    const priceRange = {
        fullPriceMin: lowestPrice,
        fullPriceMax: highestPrice,
        finalPriceMin: lowestPriceDiscount || 0,
        finalPriceMax: highestPriceDiscount || 0,
    }

    const { defaultImage, hoverImage } = getDetailTileArdadImages(ardadDescriptors, productState)
    const { defaultImage: thumbnailImage } = getDetailTileArdadImages(ardadDescriptors, productState, '1-1')
    const tag = handleTag(isNew, productTag)

    const containerClassnames = classNames('col col--xs-12 col--sm-8 col--md-6 col--lg-4 u-marginBottom--2xl', containerClassname)

    return (
        <div key={productCode} className={containerClassnames}>
            <DetailProductTile
                title={{ text: title, href: url }}
                subtitle={{ text: subtitle }}
                priceRange={priceRange}
                imageHref={url}
                affirm={{ price: lowestPriceDiscount }}
                defaultImage={defaultImage}
                hoverImage={hoverImage}
                imageBadgeLeft={tag && { text: tag }}
                productCode={productCode}
                location='Home Page Product Tile'
                enableFavorites={isFavoriteIconEnabled}
                category={category}
                subCategory={subcategory}
                thumbnailImage={thumbnailImage}
            />
        </div>
    )
}

const ProductTileSet = ({ products, noSort = false, containerClassname, productTileClassname, containerSelector }) => {
    const { isMobile } = useBreakpoints('desktop')

    if (!products) {
        return null
    }

    // TODO: once test 'EXP.HP_MATTRESS_TILES.DIS-271' is complete, move this sort to the server side
    const sortedProducts = noSort 
        ? products
        : products
            .filter(({ productCode }) => productsSort.includes(productCode))
            .sort((productA, productB) => productsSort.indexOf(productA.productCode) - productsSort.indexOf(productB.productCode))

    const productList = isMobile ? sortedProducts.slice(0, 3) : sortedProducts

    const containerClassnames = classNames('row u-flexJustify--center', containerClassname)

    return (
        <div className={containerClassnames} data-selector={containerSelector}>
            {productList.map(product => (
                <ProductTile key={product.productCode} product={product} containerClassname={productTileClassname}/>
            ))}
        </div>
    )
}

ProductTileSet.propTypes = {
    products: PropTypes.array,
    noSort: PropTypes.bool,
    containerClassname: PropTypes.string,
}

export default ProductTileSet
